.upload-controls {
    text-align: center;
    margin-top: 1rem;
}

.upload-button, .control-button {
    background: none;
    border: 1px solid var(--color-dark-purple);
    color: var(--color-dark-purple);
    padding: 0.5rem 1rem;
    cursor: pointer;
    transition: color 0.3s, border-color 0.3s;
    margin: 0.5rem;
}

.upload-button:hover, .control-button:hover {
    color: var(--color-light-purple);
    border-color: var(--color-light-purple);
}

.upload-progress {
    width: 100%;
    height: 20px;
    background-color: #ddd;
    margin-top: 1rem;
    border-radius: 5px;
    overflow: hidden;
}

.upload-progress-bar {
    height: 100%;
    background-color: var(--main-color);
    width: 0%;
    border-radius: 5px;
    transition: width 0.3s ease-in-out;
}