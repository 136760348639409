/* Modal.css */

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7); /* Dimmed background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    overflow-y: show;
}

.modal-container {
    max-width: 1280px;
    width: 90%; /* Adjust as needed, with a maximum width */
    margin: auto; /* Centers the modal in the overlay */
    padding: 2rem;
    background: radial-gradient(circle at center, var(--main-color), rgba(0, 0, 0, 0.1));
    color: var(--color-lightest-purple);
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(2px);
    -webkit-backdrop-filter: blur(2px);
    position: relative;
    z-index: 10000000;
}

.modal-content {
    position: relative;
}

.modal-close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    border: none;
    background: none;
    color: var(--color-lightest-purple);
    cursor: pointer;
    font-size: 1.5rem;
}

.modal-close-button:hover {
    color: var(--color-light-purple);
}

/* Input field styles */
.modal-container input,
.modal-container textarea {
    width: 100%;
    padding: 10px;
    margin-top: 15px;
    margin-bottom: 15px;
    color: var(--color-lightest-purple);
    background-color: var(--main-color);
    border-radius: 5px;
    border: 1px solid var(--color-dark-purple);
}
/* Input and Textarea focus styles */
.modal-container input:focus,
.modal-container textarea:focus {
    outline: 3px solid var(--color-light-purple);
}

/* Button styles */
.modal-container button {
    background: none;
    color: var(--color-dark-purple);
    border-radius: 5px;
    cursor: pointer;
    transition: color 0.3s, border-color 0.3s;
}

.modal-container button:hover {
    color: var(--color-light-purple);
    border-color: var(--color-light-purple);
}
/* Autocomplete Dropdown Styles */
.autocomplete-dropdown {
    position: absolute;
    background-color: radial-gradient(circle at center, var(--main-color), rgba(0, 0, 0, 0.745));
    border: 1px solid var(--color-dark-purple);
    border-radius: 5px;
    margin-left: 15px;
    backdrop-filter: blur(2px);
    -webkit-backdrop-filter: blur(2px);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    max-height: 200px;
    overflow-y: auto;
    z-index: 100000;
}

.autocomplete-item {
    padding: 10px;
    cursor: pointer;
    transition: background-color 0.2s ease;
}

.autocomplete-item:hover {
    background-color: var(--color-light-purple);
}

/* Adjustments to existing styles */
.source-input-container {
    position: relative;
}

.source-input-container input {
    flex-grow: 1;
    margin-right: 5px; /* Spacing between input and button */
    width: calc(100% - 25px); /* Adjust width as per your layout */
}
.modal-container textarea {
    width: 100%;
    padding: 10px;
    margin: 15px 0;
    color: var(--color-lightest-purple);
    background-color: var(--main-color);
    border-radius: 5px;
    border: 1px solid var(--color-dark-purple);
    resize: none; /* Disables resizing of the textarea */
}