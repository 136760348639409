.chat-history-sidebar {
    color: var(--color-lightest-purple);
    width: 20rem;
    padding: 1rem;
    position: fixed; /* Ensure side panel is fixed */
    background: var(--color-darkest-purple);
    right: 0;
    top: 0vh;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(2px);
    -webkit-backdrop-filter: blur(2px);
    display: flex;
    overflow-x: hidden;
    overflow-y: auto;
    flex-direction: column;
    height: 100vh;
}
.chat-panel-header {
    display: flex;
    align-items: center;
    border-bottom: 2px solid var(--color-light-purple);
    padding-bottom: 10px;
    margin-bottom: 20px;
}
.chat-history-sidebar h2 {
    text-align: center;
    padding: 10px;
    border-bottom: 2px solid var(--color-light-purple);
}
.chat-history-sidebar p {
    margin-left: 15px;
}
.new-chat-button {
    padding: 10px;
    margin: 10px;
    background: var(--color-light-purple);
    color: var(--color-dark-purple);
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: color 0.3s ease-in-out, background-color .3s ease-in-out;
}

.new-chat-button:hover {
    color: var(--color-darkest-purple);
    background: var(--color-lightest-purple);
}
.chat-history-nav {
    list-style: none;
    padding: 0;
    margin: 0;
    overflow-x: hidden;
    overflow-y: scroll;
}

.chat-history-nav li {
    margin-bottom: 10px;
}

.chat-history-nav li a {
    display: flex;
    align-items: center;
    color: var(--color-light-purple);
    text-decoration: none;
    padding: 10px 15px;
    border-radius: 5px;
    transition: background-color .3 ease-in-out, color .3 ease-in-out;
}

.chat-history-nav li a svg {
    margin-right: 8px;
}

.chat-history-nav li a:hover, .chat-history-nav li .active {
    background-color: var(--color-lightest-purple);
    color: var(--color-darkest-purple);
}

.chat-history-nav li .active {
    background-color: var(--color-light-purple);
    color: white;
}
/* Existing styles... */

.session-button {
    padding: 10px;
    margin: 10px;
    color: var(--color-dark-purple);
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: color 0.3s ease-in-out, background-color .3s ease-in-out;
    width: calc(100% - 20px); /* Adjust width to fit within the sidebar */
    text-align: left; /* Align text to the left */
    background-color: transparent; /* Default background */
    height: 50px; /* Fixed height */
    display: flex;
    padding: 5px;
    align-items: center; /* Vertically center text */
    overflow: hidden; /* Hide overflow */
    white-space: nowrap; /* No wrapping */
    position: relative; /* Relative positioning for inner span */
}

.session-button span {
    position: absolute;
    left: 0;
    right: 0;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: block;
    padding: 10px;
    margin-left: 20px;
    transition: transform 0.3s ease-in-out;
    display: inline-block; /* Make span inline-block to get its full width */
}
.session-button-emoji span {
    margin-right: 5px;
}

.session-button:hover span {
    overflow: visible; /* Hide overflow */
    animation-play-state: running; 
    animation: scroll-text 10s linear; /* Set a long duration */
    animation-iteration-count: 1; /* Run the animation only once */
}

/* CSS */
.active-session {
    background-color: var(--color-light-purple); /* Active session background */
}
@keyframes scroll-text {
    0% { 
      transform: translateX(0); 
    }
    100% { 
      transform: translateX(calc(-100% + 100px)); /* Adjust 100px based on container size */
    }
  }
  