.section-container {
    max-width: 1280px;
    margin: 6rem auto;
    padding: 2rem;
    color: var(--color-lightest-purple);
    background: radial-gradient(circle at center, var(--main-color), rgba(0, 0, 0, 0.1));
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    position: relative;
    z-index: 11;
    backdrop-filter: blur(2px);
    -webkit-backdrop-filter: blur(2px);
}
.skip-button {
    text-align: right;
    float: right;
}
.section-container h2 {
    text-align: left;
}
.skip-button button {
    background: none;
    border: none;
    color: var(--color-dark-purple);
    cursor: pointer;
    transition: color 0.3s;
}


.options-container button {
    background: none;
    border: none;
    color: var(--color-dark-purple);
    cursor: pointer;
    transition: color 0.3s;
}

.options-container button:hover {
    color: var(--color-light-purple);
}
.options-container {
    /* ... existing styles ... */
    transition: opacity 0.5s ease-in-out;
}

/* Add this class dynamically for animation */
.options-container-changing {
    opacity: 0; /* This will create a fade-out effect */
}
.controls {
    position: relative;
    margin-top: 11px;
}

.controls button {
    border: none;
    background: none;
    color: var(--color-dark-purple);
    cursor: pointer;
    transition: color 0.3s;
    position: absolute;  /* Position buttons absolutely */
}

.controls-right {
    right: 0;  /* Position the Next button on the right */
}

.controls-left {
    left: 0;  /* Position the Back button on the left */
}

.controls button:hover {
    color: var(--color-light-purple);
}



.disclaimer {
    margin-top: 20px;
    margin-right: 90px;
    text-align: right;
    font-size: 0.9rem;
    clear: both; /* Clear floats */
}
.disclaimer-right {
    margin-top: 20px;
    margin-right: 20px;
    text-align: right;
    font-size: 0.9rem;
    clear: both; /* Clear floats */
}


/* Additional style to show back button only from the second section onwards */
.section-container:not(:first-child) .controls-left {
    visibility: visible;
}
.options-container button {
    background: none;
    border: none;
    color: var(--color-dark-purple);
    cursor: pointer;
    transition: color 0.3s;
}

.options-container button:hover, 
.options-container button.selected-option, 
.options-container button.selected-subcategory { /* Add .selected-option to the hover selector */
    color: var(--color-light-purple);
}

.selected-option {
    color: var(--color-light-purple) !important; /* Use !important to increase specificity */
}
.selected-subcategory {
    color: var(--color-light-purple) !important;
}
