.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.app-layout {
  display: flex;
  height: 100vh;
}

.content-area {
  flex-grow: 1;
  padding: 20px;
  overflow-y: auto; /* Scrollable content area */
  margin-left: 12vw;
  margin-right: 12vw;
}

/* App.css */

:root {
  --main-color: #090014;
  --color-light-purple: #b39ddb;
  --color-dark-purple: #56479c;
  --color-lightest-purple: #ede7f6;
  --color-darkest-purple: #1a003c;

  /* Font sizes */
  --font-size-small: 0.8rem;
  --font-size-normal: 1rem;
  --font-size-large: 1.2rem;
  --font-size-xlarge: 1.5rem;

  /* Animation durations */
  --animation-duration: 0.3s;
}

body {
  font-family: 'Poppins', sans-serif;
  color: var(--color-light-purple);
  background: radial-gradient(circle at center, var(--color-darkest-purple), var(--main-color));
  margin: 0;
  padding: 0;
  transition: background-color var(--animation-duration) ease-in-out;
}

h1, h2, h3, h4, h5, h6 {
  color: var(--color-light-purple);
  font-weight: bold;
}

a {
  color: var(--color-light-purple);
  text-decoration: none;
  transition: color var(--animation-duration) ease-in-out;
}

a:hover, a:focus {
  color: var(--color-dark-purple);
}

button {
  background-color: var(--color-dark-purple);
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color var(--animation-duration) ease-in-out;
}

button:hover {
  background-color: var(--color-light-purple);
}

/* Animation classes */
.fade-in {
  animation: fadeIn var(--animation-duration) ease-in-out;
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}


@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: var(--color-dark-purple);
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@media (max-width: 1920px) {
  .content-area{
    margin-left: 16vw;
  }
}