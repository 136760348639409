/* DataSources.css */

.data-sources-container {
    max-width: 1280px;
    margin: 2rem auto;
    padding: 2rem;
    color: var(--color-lightest-purple);
    background: radial-gradient(circle at center, var(--main-color), rgba(0, 0, 0, 0.1));
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    position: relative;
    backdrop-filter: blur(2px);
    -webkit-backdrop-filter: blur(2px);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.data-sources-list {
    list-style: none;
    padding: 0;
    width: 100%;
}
.data-sources-list button {
    padding: 10px 20px;
    margin-top: 20px;
    background: none;
    color: var(--color-dark-purple);
    cursor: pointer;
    display: flex;
    align-items: center;
    transition: color 0.3s, background-color 0.3s;
}
.create-data-source-button:hover {
    color: var(--color-light-purple);
    border-color: var(--color-light-purple);
}
.data-sources-list li {
    padding: 10px 15px;
    border-bottom: 1px solid var(--color-dark-purple);
    transition: background-color 0.3s, color 0.3s;
}

.create-data-source-button {
    padding: 10px 20px;
    margin-top: 20px;
    background: none;
    border: 2px solid var(--color-dark-purple);
    color: var(--color-dark-purple);
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
    transition: color 0.3s, border-color 0.3s, background-color 0.3s;
}

.create-data-source-button:hover {
    color: var(--color-light-purple);
    border-color: var(--color-light-purple);
    background-color: var(--color-dark-purple);
    transform: scale(1.05);
}

.create-data-source-button .mdi-icon {
    margin-right: 10px;
}

.source-input-container {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.source-input-container input {
    flex-grow: 1;
    margin-right: 5px; /* Spacing between input and button */
}

/* Animations for list items */
@keyframes slideIn {
    from {
        transform: translateX(-100px);
        opacity: 0;
    }
    to {
        transform: translateX(0);
        opacity: 1;
    }
}

.data-sources-list li {
    animation: slideIn 0.5s ease forwards;
    position: relative;
}

.data-sources-list li:hover .tooltip {
    opacity: 1;
    visibility: visible;
}

/* More fields for the data sources container */
.data-source-detail {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 10px;
}

.data-source-detail input, .data-source-detail textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid var(--color-dark-purple);
    border-radius: 4px;
    margin-bottom: 10px;
}

.refresh-button-container {
    position: absolute;
    top: 2rem; /* Adjust as needed to align with the h1 */
    right: 2rem; /* Adjust as needed */
    display: flex;
    align-items: center;
}

.refresh-button {
    background-color: transparent;
    text-align: right;
    color: var(--color-dark-purple);
    transition: color 0.3s ease-in-out;
}
.refresh-button:hover {
    background-color: transparent;
    color: var(--color-light-purple);

}