/* ProfileCustomization.css */

.profile-customization {
    max-width: 1280px;
    margin: 2rem auto;
    padding: 2rem;
    color: var(--color-lightest-purple);
    background: radial-gradient(circle at center, var(--main-color), rgba(0, 0, 0, 0.1));
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    position: relative;
    backdrop-filter: blur(2px);
    -webkit-backdrop-filter: blur(2px);
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.profile-image-upload {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
}

.profile-image-upload label {
    cursor: pointer;
    margin-bottom: 10px;
    color: var(--color-dark-purple);
}

.profile-image-upload button {
    padding: 10px 20px;
    background: none;
    border: 2px solid var(--color-dark-purple);
    color: var(--color-dark-purple);
    border-radius: 5px;
    cursor: pointer;
    transition: color 0.3s, border-color 0.3s;
}

.profile-image-upload button:disabled {
    color: #ccc;
    border-color: #ccc;
}

.profile-image-upload button:hover {
    color: var(--color-light-purple);
    border-color: var(--color-light-purple);
}

.profile-image-container {
    text-align: center;
    margin-right: 20px;
}

.profile-image-container img {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    object-fit: cover;
}

.custom-text-field, .custom-dropdown, .custom-radio-buttons {
    margin-bottom: 15px;
}

.custom-text-field input, .custom-dropdown select {
    width: 100%;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
}

.custom-radio-buttons label {
    margin-right: 10px;
    cursor: pointer;
    color: var(--color-dark-purple);
    transition: color 0.3s;
}

.custom-radio-buttons label:hover {
    color: var(--color-light-purple);
}

/* Additional styles for other customization fields */
