/* ContentContainer.css */
.content-container {
    max-width: 1280px;
    margin: 6rem auto;
    padding: 2rem;
    color: var(--color-lightest-purple);
    background: radial-gradient(circle at center, var(--main-color), rgba(0, 0, 0, 0.1));
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    position: relative;
    z-index: 11;
    backdrop-filter: blur(2px);
    -webkit-backdrop-filter: blur(2px);
  }
  @keyframes fadeIn {
    0% {
      opacity: 0;
      transform: translateY(20px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  .animated-fadeIn {
    animation: fadeInOut 2s ease-in forwards;
  }
  
  /* Buttons - General Style */
  .content-container .mobile-content-container .button-primary {
    font-size: 1rem;
    padding: 0.5rem 1rem;
    margin-bottom: 1rem;
    border: none;
    border-radius: 5px;
    background-color: var(--color-dark-purple);
    color: white;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  @media (max-width: 480px) {
    .section{
      margin: 0;
    }
    .highlight-text-center {
      text-align: center;
      font-weight: bold;
      color: var(--color-light-purple);
      font-size: larger;
    }
    .section h1 {
      color: var(--color-light-purple);
      font-size: 1.6rem;
      font-weight: 600;
      margin-bottom: 0.5rem;
      letter-spacing: 1px;
      text-align: center;
    }
    .section-text h2 {
      color: var(--color-light-purple);
      font-size: 1.1rem;
      font-weight: 600;
      margin-bottom: 0.5rem;
      text-align: left;
    }
    .section h3 {
      color: var(--color-light-purple);
      font-size: 1rem;
      font-weight: 600;
      margin-bottom: 0.5rem;
      letter-spacing: 1px;
      text-align: left;
    }
  }
  .content-container h1 {
    color: var(--color-light-purple);
    font-size: 2rem;
    font-weight: 600;
    margin-bottom: 0.5rem;
    letter-spacing: 1px;
    text-align: center;
  }
  /* Typography */
  .content-container h2, .content-container h3, .content-container h4, .content-container h5, .content-container h6 {
    color: var(--color-light-purple);
    font-size: 2rem;
    font-weight: 600;
    margin-bottom: 0.5rem;
    letter-spacing: 1px;
  }
  
  .highlight-text {
    font-weight: bold;
    color: var(--color-light-purple);
    font-size: larger;
  }
  
  .highlight-text-list {
    font-weight: bold;
    color: var(--color-light-purple);
    font-size: larger;
    margin-right: 30px;
    margin-left: 15px;
  }
  .content-container p {
    font-size: 1rem;
    line-height: 1.6;
    margin-bottom: 1rem;
  }
  .mobile-content-container p {
    font-size: 1rem;
    line-height: 1.6;
    margin-bottom: 1rem;
    margin-left: 15px;
    margin-right: 15px;
  }
  
  /* Images */
  .img-center {
    float: right;
    left: 50%;
    right: 50%;
    max-width: 25%;
    height: auto;
    margin-bottom: 1rem;
    transition: transform 0.3s ease-in-out;
  }
  .img-center-right {
    float: right;
    left: -15%;
    max-width: 20%;
    height: auto;
    margin-bottom: 1rem;
    transition: transform 0.3s ease-in-out;
  }
  .img-center-left {
    float: right;
    right: 60%;
    max-width: 25%;
    height: auto;
    margin-bottom: 1rem;
    transition: transform 0.3s ease-in-out;
  }
  .img-left {
    float: left;
    margin-right: 20px;
    max-width: 25%;
    height: auto;
    margin-bottom: 1rem;
    transition: transform 0.3s ease-in-out;
  }
  
  .img-right {
    float: right;
    margin-left: 20px;
    max-width: 25%;
    height: auto;
    margin-bottom: 1rem;
    transition: transform 0.3s ease-in-out;
  }
  
  
  
  /* Lists */
  .content-container ul, .content-container ol {
    padding-left: 20px;
    margin-bottom: 1rem;
  }
  
  .mobile-content-container ul, .mobile-content-container ol {
    padding-left: 20px;
    margin-bottom: 1rem;
  }
  .icon-bullet-list {
    list-style: none;
    padding-left: 0;
  }
  
  .icon-bullet-list li {
    position: relative;
    padding-left: 30px; /* Adjust based on icon size */
  }
  
  .icon-bullet-list li .list-icon {
    position: absolute;
    color: var(--color-light-purple);
    left: 0;
    top: 0;
  }
  .list-item-description {
    display: block;
    margin-left: 45px; /* Adjust this value to align with your icon size and padding */
    color: var(--color-lightest-purple)
  }
  
  /* Buttons and Inputs */
  .content-container input, .content-container textarea {
    font-size: 1rem;
    padding: 0.5rem 1rem;
    margin-bottom: 1rem;
    border: none;
    border-radius: 5px;
    background-color: var(--color-dark-purple);
    color: white;
    cursor: pointer;
  }
  .content-container button {
    background: none;
    border: none;
    color: var(--color-dark-purple);
    cursor: pointer;
    transition: color 0.3s;
    }
  .mobile-content-container button, .mobile-content-container input, .mobile-content-container textarea {
    font-size: 1rem;
    padding: 0.5rem 1rem;
    margin-bottom: 1rem;
    border: none;
    border-radius: 5px;
    background-color: var(--color-dark-purple);
    color: white;
    cursor: pointer;
  }
  .content-container .button-primary:hover {
    background-color: var(--color-light-purple);
  }
  /* Animations */
  @keyframes fadeInContent {
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
  }
  
  .content-container .animated-fadeIn {
    animation: fadeInContent 1s ease-in-out forwards;
  }
  .mobile-content-container .animated-fadeIn {
    animation: fadeInContent 1s ease-in-out forwards;
  }
  /* Base style for all sections */
  .section {
    color: var(--color-lightest-purple);
    border-radius: 10px;
    padding-left: 1rem;
    padding-right: 1rem;
  
  }
  .section h2 {
    color: var(--color-light-purple)
  }
  .highlight-text-center {
    color: var(--color-light-purple);
  }
  @media (min-width: 1280px) {
    .section {
        padding: 2rem;
    }
    .list-item-description {
      margin-top: 20px;
      margin-bottom: 20px;
    }
    .highlight-text-center {
      text-align: center;
      font-weight: bold;
      color: var(--color-light-purple);
      font-size: larger;
    }
    .section p {
      font-size: 1rem;
      margin-top: 1rem;
      margin-bottom: 1rem;
      line-height: 1.5;
    }
      /* Typography within sections */
    .section h2 {
      font-size: 1.5rem;
      margin-bottom: 1rem;
    }
    .section {
      margin-bottom: 2rem;
    }
  }
  
  
  
  /* For sections primarily containing lists */
  
  
  .section-list ul,
  .section-list ol {
    padding-left: 20px;
  }
  
  
  .section a {
    font-size: 1rem;
    padding: 0.5rem 1rem;
    margin-bottom: 1rem;
    border: none;
    transition: background-color 0.3s ease;
    background: none;
    border: none;
    color: var(--color-dark-purple);
    cursor: pointer;
    transition: color 0.3s;
}
  /* For sections with mixed content */
  .section-mixed {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  
  @media (min-width: 768px) {
    .section-mixed {
        flex-direction: row;
    }
  }
  /* Fade-in animation */
  @keyframes fadeIn {
    from { opacity: 0; transform: translateY(20px); }
    to { opacity: 1; transform: translateY(0); }
  }
  
  .section.fade-in {
    animation: fadeIn 1s ease-out;
  }
  
  /* Slide-in animations */
  @keyframes slideInLeft {
    from { transform: translateX(-100%); opacity: 0; }
    to { transform: translateX(0); opacity: 1; }
  }
  
  .section.slide-in-left {
    animation: slideInLeft 1s ease-out;
  }
  
  @keyframes slideInRight {
    from { transform: translateX(100%); opacity: 0; }
    to { transform: translateX(0); opacity: 1; }
  }
  
  .section.slide-in-right {
    animation: slideInRight 1s ease-out;
  }
  
  /* Scale up animation */
  @keyframes scaleUp {
    from { transform: scale(0.8); opacity: 0; }
    to { transform: scale(1); opacity: 1; }
  }
  
  .section.scale-up {
    animation: scaleUp 1s ease-out;
  }
  /* Images with Hover Effect */
  .content-container img {
    position: relative;
    max-width: 25%;
    height: auto;
    transition: transform 0.3s ease-in-out;
    animation: float 3s ease-in-out infinite;
  }
  .mobile-content-container img {
    position: relative;
    max-width: 25%;
    height: auto;
    transition: transform 0.3s ease-in-out;
    animation: float 3s ease-in-out infinite;
  }
  
  /* Images Animation */
  @keyframes float {
    0%, 100% { transform: translateY(0); }
    50% { transform: translateY(-10px); }
  }
  .img-left, .img-right, .img-center, .img-center-right, .img-center-left {
    margin-left: 20px; /* Adjust for left-aligned or centered images */
    margin-right: 20px; /* Adjust for right-aligned or centered images */
    transition: transform 0.3s ease-in-out;
    animation: float 3s ease-in-out infinite;
  
  }
  
  @media (max-width: 768px) {
    .img-left, .img-right {
      /* Adjustments for smaller screens */
      margin-left: 20px;
      margin-right: 20px;
    }
  }
  @media (max-width: 480px) {
    .content-container{
      margin-left: 0px;
    }
    .highlight-text-list, .list-item-description {
      display: block; /* Change to block for a better mobile layout */
      margin: 0 10px; /* Adjust margins for smaller screens */
    }
  }