.chat-model-container {
    max-width: 1280px;
    margin: 2rem auto;
    padding: 2rem;
    color: var(--color-lightest-purple);
    background: radial-gradient(circle at center, var(--main-color), rgba(0, 0, 0, 0.1));
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    position: relative;
    backdrop-filter: blur(4px);
    display: flex;
    flex-direction: column;
    align-items: flex-start; /* center align */
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.chat-model-container:hover {
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.25);
}

.data-source-item {
    display: flex;
    align-items: center;
    padding: 10px 15px;
    border-bottom: 1px solid var(--color-dark-purple);
    animation: slideIn 0.5s ease forwards;
}

.data-source-name, .data-source-file-count {
    margin-left: 10px;
}

.create-chat-model-button {
    /* Similar styling to .create-data-source-button */
    padding: 10px 20px;
    margin-top: 20px;
    background: none;
    border: 2px solid var(--color-dark-purple);
    color: var(--color-dark-purple);
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    text-align: left;
    transition: color 0.3s, border-color 0.3s, background-color 0.3s;
}

.create-chat-model-button:hover {
    color: var(--color-light-purple);
    border-color: var(--color-light-purple);
    background-color: var(--color-dark-purple);
    transform: scale(1.05);
}
.model-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
    animation: fadeIn 1s ease;
}

.model-table th, .model-table td {
    text-align: left;
    padding: 8px;
    border-bottom: 1px solid var(--color-dark-purple);
}

.model-table th {
    background-color: var(--color-dark-purple);
    color: var(--color-lightest-purple);
}

.model-table tr:nth-child(even) {
    background-color: var(--color-darkest-purple);
}

.model-table tr:hover {
    background-color: var(--color-dark-purple);
    color: var(--color-lightest-purple);
    cursor: pointer;
}

@keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
}
.data-source-item {
    display: flex;
    align-items: center;
    padding: 10px 15px;
    border-bottom: 1px solid var(--color-dark-purple);
    transition: background-color 0.3s ease, color 0.3s ease;
}

.data-source-item:hover {
    background-color: var(--color-dark-purple);
    color: white;
}
.custom-instructions {
    margin-top: 20px;
}
.custom-instructions label {
    font-weight: bold;
    color: var(--color-light-purple)
}
.submit-button {
    padding: 10px 20px;
    margin-top: 20px;
    background: var(--color-dark-purple);
    color: var(--color-lightest-purple);
    border-radius: 5px;
    cursor: pointer;
    border: none;
    transition: background-color 0.3s, color 0.3s;
}

.submit-button:hover {
    background-color: var(--color-light-purple);
}

.chat-models-list {
    list-style: none;
    padding: 0;
    width: 100%;
}

.chat-model-list-item {
    padding: 10px 15px;
    border-bottom: 1px solid var(--color-dark-purple);
    transition: background-color 0.3s, color 0.3s;
    cursor: pointer;
    display: flex;
    justify-content: space-between; /* Align items to the start and end */
}

.chat-model-list-item:hover {
    background-color: var(--color-dark-purple);
    color: var(--color-lightest-purple);
}

/* Include animations from your existing CSS */

@keyframes slideIn {
    from {
        transform: translateX(-100px);
        opacity: 0;
    }
    to {
        transform: translateX(0);
        opacity: 1;
    }
}

.chat-models-list li {
    animation: slideIn 0.5s ease forwards;
    position: relative;
}
.create-ds-intgr a {
    color: var(--color-dark-purple);
    transition: color .3s ease-in-out;
}
.create-ds-intgr a:hover{
    color: var(--color-light-purple);
}