/* ChatMessages.css */
@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@500&display=swap');

.chat-messages {
    display: flex;
    flex-direction: column;
    gap: 15px;
    width: 35vw;
    overflow-y: auto;
    overflow-x: hidden;
    margin-top: 20px;
    margin: 20px auto;
    margin-bottom: 20px;
    border-radius: 8px;
    padding: 15px;
}

.chat-messages.initial-margin {
    margin-top: 20px;
}

.code-container {
    position: relative;
    background: var(--main-color);
    border-radius: 6px;
    overflow: hidden;
    margin-top: 10px;
    max-width: 30vw;
    align-items: center;
    justify-content: space-around;
    margin-left: 100px;
    margin-right: 100px;
}
.code-ribbon {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: var(--color-darkest-purple);
    padding: 5px 10px;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
}

.language-icon {
    color: var(--color-dark-purple);
    /* Additional styling as needed */
}

.copy-button {
    background: none;
    color: var(--color-dark-purple);
    border: none;
    margin: 0;
    padding: 0;
    cursor: pointer;
    font-size: 1em;
    transition: all ease-in-out 0.3s;
}

.copy-button:hover {
    color: var(--color-light-purple);
    background-color: transparent;
}


/* Markdown Content Styles */
.message-content h1, .message-content h2, .message-content h3 {
    color: var(--color-light-purple);
    font-weight: bold;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
    .chat-messages {
        width: 80vw;
        margin: 0 10vw;
    }
}
.message {
    padding: 10px;
    color: var(--color-lightest-purple);
    background: rgba(255, 255, 255, 0.1);
    border-radius: 6px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    transition: all 0.3s ease;
}

.message:hover {
    transform: translateY(-3px);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
}

.message-author {
    font-weight: bold;
    margin-bottom: 5px;
    color: var(--color-light-purple);
}
.message-content {
    margin-left: 20px;
    margin-right: 20px;
}
/* Additional Styling */
.message-content a {
    color: var(--color-light-purple);
    text-decoration: underline;
}
.message-content p code {
    color: var(--color-dark-purple);
    font-weight: bold;
    font-size: large;
    font-family: 'Roboto', monospace;
}
.message-content a:hover {
    color: var(--color-dark-purple);
}


.code-container code {
    overflow-x: scroll; /* Wrap long lines */
}
.hljs {
    background: transparent;
    padding: 15px;
    margin: 0;
    font-family: 'Roboto', monospace; /* Use Roboto Mono for better readability */
    /* or font-family: 'Source Code Pro', monospace; */
    font-weight: 500; /* A bolder weight for better readability */
    font-size: 1em; /* Adjust the font size as needed */
    line-height: 1.5; /* Increased line height for readability */
    text-shadow: none;
}
.hljs-comment, .hljs-quote {
    color: #6d9cbe; /* Cool Blue for comments */
}

.hljs-variable, .hljs-template-variable {
    color: #c96765; /* Warm Red for variables */
}

.hljs-attribute {
    color: #f9c859; /* Bright Yellow for attributes */
}

.hljs-tag {
    color: #5ab738; /* Vivid Green for tags */
}

.hljs-name {
    color: #d88537; /* Earthy Orange for names */
}

.hljs-regexp, .hljs-link {
    color: #e37933; /* Bold Orange for regex and links */
}

.hljs-meta {
    color: #557182; /* Deep Sea Blue for meta */
}

.hljs-number, .hljs-built_in, .hljs-builtin-name {
    color: #3986ac; /* Ocean Blue for numbers and built-ins */
}

.hljs-literal {
    color: #569cd6; /* Sky Blue for literals */
}

.hljs-type, .hljs-params {
    color: #9b5c2e; /* Rusty Brown for types and params */
}

.hljs-string {
    color: #4dbf99; /* Aquamarine for strings */
}

.hljs-symbol, .hljs-bullet {
    color: #ac4c1e; /* Copper for symbols and bullets */
}

.hljs-title, .hljs-section {
    color: #1c92a9; /* Teal for titles and sections */
}

.hljs-keyword, .hljs-selector-tag {
    color: #d670d6; /* Magenta for keywords and selectors */
}

.hljs {
    font-family: 'Roboto Mono', monospace;
    font-weight: 500;
    font-size: 1em;
    line-height: 1.5;
    text-shadow: none;
}

.hljs-emphasis {
    font-style: italic;
}

.hljs-strong {
    font-weight: bold;
}