.ig-caption-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: scroll;
    width: 1280px;
}

.ig-caption-form {
    margin-bottom: 20px;
}

.uploaded-image {
    max-width: 100%;
    height: auto;
    margin-bottom: 20px;
}

.response-container {
    background-color: var(--main-color);
    border: 1px solid var(--color-light-purple);
    border-radius: 10px;
    padding: 15px;
    color: var(--color-lightest-purple);
    width: 100%;
    max-width: 1750px;
    margin-top: 20px;
    overflow-wrap: break-word;
}

.response-container pre {
    white-space: pre-wrap; /* Since JSON.stringify() is used */
}
