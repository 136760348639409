/* Updated styles for OpenAPIForm */
.api-form-container {
    max-width: 1280px;
    margin: 2rem auto;
    padding: 2rem;
    background: radial-gradient(circle at center, var(--main-color), rgba(0, 0, 0, 0.1));
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(4px);
    display: flex;
    flex-direction: column;
    color: var(--color-lightest-purple);
}

.api-form-dropdown {
    width: 100%;
    margin-bottom: 20px;
}

.api-form-dropdown select {
    width: 100%;
    padding: 10px;
    background-color: var(--color-darkest-purple);
    color: var(--color-lightest-purple);
    border: 1px solid var(--color-dark-purple);
    border-radius: 5px;
    font-size: 16px;
}

.api-form-dropdown select:focus {
    outline: none;
    border-color: var(--color-light-purple);
}

.api-method-details, .api-global-info {
    padding: 15px;
    border-radius: 5px;
    margin-top: 20px;
    color: var(--color-lightest-purple);
}

.api-method-details h3, .api-global-info h2 {
    color: var(--color-light-purple);
    margin-bottom: 10px;
}

.api-global-info p, .api-method-details p {
    margin: 5px 0;
}

.api-global-info a, .api-method-details a {
    color: var(--color-light-purple);
    text-decoration: none;
}

.api-global-info a:hover, .api-method-details a:hover {
    text-decoration: underline;
}

.parameter-input-container {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.parameter-input-container input {
    flex-grow: 1;
    margin-right: 10px;
    padding: 10px;
    border: 1px solid var(--color-dark-purple);
    border-radius: 4px;
    background-color: rgba(255, 255, 255, 0.1);
    color: var(--color-lightest-purple);
}

.parameter-input-container input:focus {
    outline: none;
    border-color: var(--color-light-purple);
}

.parameter-input-container label {
    width: 150px;
    margin-right: 10px;
    text-align: right;
    color: var(--color-light-purple);
}

.api-form-submit-button {
    padding: 10px 20px;
    background-color: var(--color-dark-purple);
    color: var(--color-lightest-purple);
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
    align-self: flex-end;
}

.api-form-submit-button:hover {
    background-color: var(--color-light-purple);
}

.api-global-info .api-contact-info, .api-global-info .api-terms, .api-global-info .api-license, .api-global-info .api-client-registration {
    margin-top: 10px;
    font-size: 0.9em;
}
/* Styles for API response and error display */
.api-response, .api-error {
    margin-top: 20px;
    padding: 15px;
    border-radius: 5px;
    background-color: rgba(255, 255, 255, 0.1);
    color: var(--color-lightest-purple);
    overflow-wrap: break-word; /* Ensures text wrapping */
}

.api-response h4, .api-error h4 {
    color: var(--color-light-purple);
    margin-bottom: 10px;
}

.api-response pre, .api-error p {
    white-space: pre-wrap; /* Allows for natural text wrapping within <pre> */
    word-break: break-all; /* Breaks words to prevent overflow */
    font-size: 0.9em;
    line-height: 1.4;
}

.api-error {
    background-color: rgba(255, 77, 77, 0.1); /* Light red background for error */
}

/* Additional styles for better readability */
pre {
    padding: 10px;
    border-radius: 5px;
    background-color: rgba(0, 0, 0, 0.1);
    box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.2);
}

/* Styles for buttons */
.api-form-submit-button, .api-auth-button {
    padding: 10px 20px;
    background-color: var(--color-dark-purple);
    color: var(--color-lightest-purple);
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
    align-self: flex-end;
}

.api-form-submit-button:hover, .api-auth-button:hover {
    background-color: var(--color-light-purple);
}