.chat-container {
    padding: 0; /* Remove padding if it exists */
    display: flex;
    justify-content: center; /* Center children horizontally */
    height: 85vh; /* Adjust height as needed */
}

.chat-area {
    flex-grow: 1;
    display: flex;
    justify-content: center; /* Ensure this is centered as well */
}
.dropdown {
    position: absolute; /* Positioning relative to the nearest positioned ancestor */
    right: 12vw; /* Align to the right */
    top: 2vh; /* Align to the top, adjust as needed */
    background-color: transparent;
    border: none;
    margin-left: 15px;
    justify-content: right;
    color: var(--color-light-purple);
    backdrop-filter: blur(2px);
    font-size: large;
    font-weight: bold;
    max-height: 4vw;
    overflow-y: auto;
    z-index: 100000;
    border-color: none;
}
.dropdown select {
    padding: 5px;
    background-color: radial-gradient(var(--main-color), var(--color-darkest-purple), rgba(0,0,0,0.4));
    color: var(--color-light-purple);
    border: none;
    border-color: none;
    background-color: transparent;
    border: none;
    margin-left: 15px;
    justify-content: right;
    color: var(--color-light-purple);
    backdrop-filter: blur(2px);
    font-size: large;
    font-weight: bold;
    max-height: 4vw;
    overflow-y: auto;
    z-index: 100000;
    border-color: none;
}
.dropdown select option {
    background-color: radial-gradient(var(--main-color), var(--color-darkest-purple), rgba(0,0,0,0.4));
    color: var(--color-light-purple);
    border-color: none;
}
