/* ViewModal.css */
@keyframes modalEntry {
    from {
        transform: translateY(-50px);
        opacity: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
}

.modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid var(--color-light-purple);
    padding-bottom: 15px;
    margin-bottom: 20px;
}

.modal-header h2 {
    margin: 0;
    color: var(--color-lightest-purple);
    font-size: 24px;
}

.modal-body {
    max-height: 400px;
    overflow-y: auto;
    color: var(--color-light-purple);
    animation: fadeIn 1s ease-in-out;
    background: radial-gradient(circle at center, var(--main-color), rgba(0, 0, 0, 0.1));
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(4px);
}

.modal-body ul {
    list-style: none;
    padding: 0;
    margin: 0;
}
/* ViewModal.css */

/* ... [other styles] ... */

.modal-body .file-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}

.modal-body .file-header-name {
    flex: 3; /* Adjust as necessary to allocate space */
    font-weight: bold;
    color: var(--color-lightest-purple);
    margin-left: 20px;
}

.modal-body .file-header-type {
    flex: 1; /* Adjust for a straight column */
    font-weight: bold;
    color: var(--color-lightest-purple);
    text-align: center; /* Align text center */
}

.modal-body .file-header-size {
    flex: 1; /* Adjust as necessary */
    font-weight: bold;
    color: var(--color-lightest-purple);
    text-align: right; /* Align text to the right */
    margin-right: 20px;
}

.modal-body li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid var(--color-dark-purple);
    padding: 10px 0;
    transition: background-color 0.3s, color 0.3s;
    cursor: pointer;
    animation: slideIn 0.5s ease forwards;
}

.modal-body li .file-name {
    flex: 3; /* Corresponds with header */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-left: 10px;
}

.modal-body li .file-type {
    flex: 1; /* Corresponds with header */
    text-align: center; /* Align text center */
}

.modal-body li .file-size {
    flex: 1; /* Corresponds with header */
    text-align: right;
    margin-right: 20px;
}

/* ... [other styles] ... */

.modal-body li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid var(--color-dark-purple);
    padding: 10px 0;
    transition: background-color 0.3s, color 0.3s;
    cursor: pointer;
    animation: slideIn 0.5s ease forwards;
}

.modal-body li:nth-child(even) {
    background-color: var(--color-darkest-purple);
}

.modal-body li:hover {
    background-color: var(--color-dark-purple);
    color: var(--color-lightest-purple);
}

.modal-body li .file-name,
.modal-body li .file-size {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

@keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
}

.modal-footer {
    text-align: right;
    margin-top: 20px;
}

.modal-footer button {
    color: var(--color-dark-purple);
    border: 2px solid var(--color-dark-purple);
    background: none;
    border-radius: 5px;
    padding: 10px 20px;
    cursor: pointer;
    transition: color 0.3s ease, background-color 0.3s ease;
}

.modal-footer button:hover {
    color: white;
    background-color: var(--color-dark-purple);
}

.modal-footer button:disabled {
    color: transparent;
    cursor: none;
    z-index: -102;
    background: transparent;
    border: transparent;
}

.pagination {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}
