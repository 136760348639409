/* SidePanel.css */

.side-panel {
    background-color: var(--color-darkest-purple);
    color: var(--color-lightest-purple);
    width: 10vw;
    height: 100vh;
    padding: 20px;
    box-sizing: border-box;
    position: fixed; /* Ensure side panel is fixed */
}

.side-panel-header {
    display: flex;
    align-items: center;
    border-bottom: 2px solid var(--color-light-purple);
    padding-bottom: 10px;
    margin-bottom: 20px;
}

.side-panel-logo {
    width: 40px;  /* Adjust size as needed */
    height: auto;
    margin-right: 10px;
}

.side-panel-title {
    font-size: var(--font-size-xlarge);
    margin: 0;
}

.side-panel-nav {
    list-style: none;
    padding: 0;
    margin: 0;
}

.side-panel-nav li {
    margin-bottom: 10px;
}

.side-panel-nav li a {
    display: flex;
    align-items: center;
    color: var(--color-light-purple);
    text-decoration: none;
    padding: 10px 15px;
    border-radius: 5px;
    transition: background-color var(--animation-duration) ease-in-out, color var(--animation-duration) ease-in-out;
}

.side-panel-nav li a svg {
    margin-right: 8px;
}

.side-panel-nav li a:hover, .side-panel-nav li .active {
    background-color: var(--color-lightest-purple);
    color: var(--color-darkest-purple);
}

.side-panel-nav li .active {
    background-color: var(--color-light-purple);
    color: var(--color-dark-purple);
}

.side-panel-profile {
    position: fixed;
    bottom: 0;
    width: calc(10vw - 40px);
    padding: 20px;
    box-sizing: border-box;
    border-top: 2px solid var(--color-light-purple);
    cursor: pointer;
}

.profile-info {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.profile-picture {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: var(--color-lightest-purple); /* Placeholder style */
    margin-right: 10px;
}

.profile-name {
    font-weight: bold;
}

.profile-menu {
    position: absolute;
    bottom: 100%;
    left: 20px;
    width: calc(100% - 20px);
    background-color: var(--color-light-purple);
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0 -5px 10px rgba(0, 0, 0, 0.2);
    z-index: 2;
}

.profile-menu ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.profile-menu ul li {
    margin-bottom: 10px;
}

.profile-menu ul li a, .profile-menu ul li button {
    display: flex;
    align-items: center;
    color: var(--color-darkest-purple);
    text-decoration: none;
    padding: 10px;
    background: none;
    border: none;
    width: 100%;
    text-align: left;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.profile-menu ul li a:hover, .profile-menu ul li button:hover {
    background-color: var(--color-lightest-purple); /* Adjust as needed */
}

.profile-menu ul li a.active {
    background-color: var(--color-dark-purple);
    color: white;
}
.profile-menu ul li a svg, .profile-menu ul li button svg {
    margin-right: 8px;
}

.sign-out-button {
    background: none;
    border: none;
    border-radius: 10px;
}
@media (max-width: 1920px) {
    .side-panel {
        width: 15vw;
    }
    .side-panel-profile {
        width: calc(15vw - 40px);
    }
}