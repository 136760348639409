.chat-container {
    display: flex;
    flex-direction: column;
    height: 2em; /* Initial height */
    justify-content: flex-start;
    align-items: center;
    padding-bottom: 60px; /* Space for the textarea */
}
.chat-input-container {
    position: fixed;
    bottom: 15px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    align-items: flex-end;
}


.chat-input {
    display: flex;
    padding: 10px;
    border-radius: 5px;
    position: fixed;
    bottom: 15px; /* Distance from the bottom */
    left: 50%; /* Centering technique */
    transform: translateX(-50%); /* Centering technique */
    width: auto; /* Auto width based on content */
    transition: height var(--textarea-transition-time) ease-out;
    --textarea-transition-time: 0.3s; /* Default transition time */
}


.chat-input textarea {
    flex-grow: 1;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid var(--color-dark-purple);
    background: var(--color-darkest-purple);
    color: var(--color-lightest-purple);
    outline: none;
    width: 30vw;
    max-height: 30rem;
    min-height: 50px;
    overflow: hidden; /* Hide the scrollbar */
    resize: none; /* Prevent manual resize */
    transition: height var(--textarea-transition-time) ease-out;
    --textarea-transition-time: 0.3s; /* Default transition time */
}

.chat-input button {
    background: transparent;
    color: var(--color-dark-purple);
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: color 0.3s;
}

.chat-input button:hover {
    color: var(--color-light-purple);
}

/* Adjustments for smaller screens */
@media (max-width: 768px) {
    .chat-input {
        width: 80%; /* Adjust width for smaller screens */
    }

    .chat-input textarea {
        width: 100%; /* Full width in smaller screens */
    }
}
.file-upload-button {
    background: var(--color-dark-purple);
    border: none;
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 5px;
    transition: background-color 0.3s;
}

.file-upload-button:hover {
    background: var(--color-light-purple);
}

.file-upload-button svg {
    fill: var(--color-lightest-purple);
}
