
.integrations-container {
    max-width: 1280px;
    margin: 2rem auto;
    padding: 2rem;
    color: var(--color-lightest-purple);
    background: radial-gradient(circle at center, var(--main-color), rgba(0, 0, 0, 0.1));
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    position: relative;
    backdrop-filter: blur(2px);
    -webkit-backdrop-filter: blur(2px);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.integrations-button {
    padding: 10px 20px;
    margin-top: 20px;
    background: none;
    border: 2px solid var(--color-dark-purple);
    color: var(--color-dark-purple);
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
    transition: color 0.3s, border-color 0.3s, background-color 0.3s;
}
.integrations-button:hover {
    color: var(--color-light-purple);
    border-color: var(--color-light-purple);
    background-color: var(--color-dark-purple);
    transform: scale(1.05);
}
.integrations-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    animation: fadeIn 0.5s ease-in-out;
}

@keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
}

.integrations-header h2 {
    margin: 0;
    color: var(--color-lightest-purple);
    font-size: 24px;
    transition: transform 0.3s ease-in-out;
}

.integrations-header h2:hover {
    transform: scale(1.05);
}

.integrations-search {
    display: flex;
    flex-direction: column;
    position: relative;
    margin-bottom: 20px;
}

.search-bar {
    position: relative;
    display: flex;
    width: 100%;
}

.integrations-search input {
    flex-grow: 1;
    padding: 10px;
    border-radius: 10px;
    border: 1px solid var(--color-dark-purple);
    background: var(--color-darkest-purple);
    color: var(--color-lightest-purple);
    transition: box-shadow 0.3s ease-in-out;
}

.integrations-search input:focus {
    box-shadow: 0 0 8px var(--color-light-purple);
}

.integrations-search button {
    padding: 10px 20px;
    margin-left: 10px;
    background: transparent;
    color: var(--color-dark-purple);
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.3s ease;
}

.integrations-search button:hover {
    color: var(--color-light-purple);
}

.search-results {
    margin-top: 10px;
    overflow-x: auto;
    border-radius: 5px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
}

.search-results table {
    width: 100%;
    border-collapse: collapse;
    animation: slideIn 0.5s ease forwards;
}

@keyframes slideIn {
    from { transform: translateY(-20px); opacity: 0; }
    to { transform: translateY(0); opacity: 1; }
}

.search-results th, .search-results td {
    padding: 8px;
    text-align: left;
    border-bottom: 1px solid var(--color-dark-purple);
}

.search-results tr:nth-child(even) {
    background-color: var(--color-darkest-purple);
}

.search-results th {
    background-color: var(--color-dark-purple);
    color: var(--color-lightest-purple);
}

.search-results tr:hover {
    background-color: var(--color-dark-purple);
    color: var(--color-lightest-purple);
    transition: background-color 0.3s ease-in-out;
    cursor: pointer;
}

/* Responsive Design */
@media (max-width: 768px) {
    .integrations-container {
        padding: 1rem;
    }

    .integrations-header h2 {
        font-size: 20px;
    }

    .api-form {
        padding: 10px;
    }
}